import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import StartupView from '../views/StartupView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'startup',
    component: StartupView
  },
  {
    path: '/browser/install',
    name: 'browserInstall',
    component: () => import('../views/BrowserInstallView.vue')
  },
  {
    path: '/browser/choice',
    name: 'browserChoice',
    component: () => import('../views/BrowserChoiceView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router;
