import { useStoreSettings } from '@/stores/settings';
import { useStoreAccounts } from '@/stores/accounts';
import { useIpcRenderer } from '@/use/useIpcRenderer';


export const useSituation = {
  SITUATION_BROWSER_INSTALL: 'SITUATION_BROWSER_INSTALL',
  SITUATION_BROWSER_CHOICE: 'SITUATION_BROWSER_CHOICE',
  SITUATION_NOT_LOGGED_IN: 'SITUATION_NOT_LOGGED_IN',

  async getCurrent() {
    const storeSettings = useStoreSettings();
    const storeAccounts = useStoreAccounts();

    if (!storeSettings.hasBrowser) {
      const { ipcSendSync } = useIpcRenderer();
      const browsers = (await ipcSendSync('system.getBrowsers')).data;

      if (!browsers.length) return this.SITUATION_BROWSER_INSTALL;

      return this.SITUATION_BROWSER_CHOICE;
    }

    if (!storeSettings.account.loggedIn) return this.SITUATION_NOT_LOGGED_IN;

    return 123;
  },
};