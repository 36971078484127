import { reactive, watch } from 'vue';
import { defineStore } from 'pinia';

const STORE_NAME = 'storeRuntime';

export const useStoreRuntime = defineStore(STORE_NAME, {
  state: (): StateRuntime => {
    const runtime = reactive<StateRuntime>({
      ui: {
        popover: {
          active: false,
          title: undefined,
          subtitle: undefined,
          progressbar: {
            active: false,
          },
          buttons: [],
        },
      }
    });

    return runtime;
  },
  getters: {
  },
  actions: {
    showPopover(active: boolean, title?: string, subtitle?: string, reset = true) {
      this.ui.popover.active = active;
      this.ui.popover.title = title;
      this.ui.popover.subtitle = subtitle;

      if (reset) {
        this.ui.popover.progressbar.active = false;
        this.ui.popover.buttons = [];
      }

      return this.ui.popover;
    },
  },
});