import { reactive, watch } from 'vue';
import { defineStore } from 'pinia';
import { uuid } from 'vue-uuid';

const STORE_NAME = 'storeSettings';

export const useStoreSettings = defineStore(STORE_NAME, {
  state: (): StateSettings => {
    const localItem = JSON.parse(localStorage.getItem(STORE_NAME) ?? String(null));
    const settings = reactive<StateSettings>(Object.assign(
      {
        browser: {
          name: undefined,
          path: undefined,
        },
        account: {
          id: undefined,
          loggedIn: false,
        },
      },
      localItem
    ));

    watch(settings, (state) => {
      localStorage.setItem(STORE_NAME, JSON.stringify(state));
    }, {deep: true});

    return settings;
  },

  getters: {
    hasBrowser: (state): boolean => {
      return (!!state.browser.name && !!state.browser.path);
    }
  },
  actions: {
    // getFolder(id: string): (Folder | undefined) {
    //   return this.folders.find(folder => folder.id == id);
    // },
    // addFolder(title: string) {
    //   const folder = {
    //     id: uuid.v1(),
    //     title,
    //     pageIds: [],
    //   }
    //   this.folders.push(folder);
    // },
  },
});