import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

import Toast, { POSITION } from 'vue-toastification';
  // https://github.com/Maronato/vue-toastification
import VueFeather from 'vue-feather';
  // https://fengyuanchen.github.io/vue-feather/
  // https://www.vuescript.com/feather-icons-component/

const pinia = createPinia();

createApp(App)
  .use(router)
  .use(pinia)
  .use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
  })
  .component(VueFeather.name, VueFeather)
  .mount('#app');
