const ELECTRON_EVENT_PREFIX = 'electron';

export function useIpcRenderer() {
  const _this = {
    // ipcSend(event: string, message: any = {}): any {
    //   return window.$electron.ipcRenderer.send(`${ELECTRON_EVENT_PREFIX}:${event}`, message);
    // },
    async ipcSendSync(event: string, message: any = {}): Promise<any> {
      let result = {};

      await _this._waitForElectronIpcRenderer();
      
      try {
        result = JSON.parse(
          window.$electron.ipcRenderer.sendSync(`${ELECTRON_EVENT_PREFIX}:${event}`, message)
        );
      } catch (e) {
        result = {
          data: [],
          error: (e as Error).message,
        };

        console.log(result);
      }

      return result;
    },
    async _waitForElectronIpcRenderer() {
      while (!window || !window.$electron || !window.$electron.ipcRenderer) {
        console.log('-> _waitForElectronIpcRenderer');
        await new Promise(r => setTimeout(r, 100));
      }
    },
    // ipcSendAsync<T>(event: string, message: any): Promise<T> {
    //   return window.$electron.ipcRenderer.invoke(`${ELECTRON_EVENT_PREFIX}:${event}`, message);
    // },
  };

  return _this;
}