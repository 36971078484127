import { reactive, watch } from 'vue';
import { defineStore } from 'pinia';
import { uuid } from 'vue-uuid';
import { Account } from 'mdue';

const STORE_NAME = 'storeAccounts';

export const useStoreAccounts = defineStore(STORE_NAME, {
  state: (): StateAccounts => {
    const localItem = JSON.parse(localStorage.getItem(STORE_NAME) ?? String(null));
    const accounts = reactive<StateAccounts>(Object.assign(
      {
        accounts: [],
      },
      localItem
    ));

    watch(accounts, (state) => {
      localStorage.setItem(STORE_NAME, JSON.stringify(state));
    }, {deep: true});

    return accounts;
  },

  getters: {
  },
  actions: {
    update(accountUpdate: Account) {
      const account = this.find(accountUpdate.id);

      if (!account) {
        this.accounts.push(accountUpdate);
      } else {
        let attribute: keyof Account;

        for (attribute in accountUpdate) {
          account[attribute] = accountUpdate[attribute];
        }
      }
    },
    find(id: string): (Account | undefined) {
      return this.accounts.find(account => account.id == id);
    },

  },
});